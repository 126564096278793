/* eslint-disable react-hooks/rules-of-hooks */
import { useCallback, useEffect, useState } from 'react'
import BigNumber from 'bignumber.js'
import { useWallet } from '@binance-chain/bsc-use-wallet'
import { getWeb3 } from 'utils/web3'
import { Contract } from 'web3-eth-contract'
import { AbiItem } from 'web3-utils'
import masterchefABI from 'config/abi/masterchef.json'
import { getMasterChefAddress } from 'utils/addressHelpers'
import { StakedUserInfo, StakedTime } from 'config/constants/types'



export const useUserInfo = () => {
  const [data, setData] = useState<StakedUserInfo>()
  // const [data, setData] = useState({})
  const { account }: { account: string } = useWallet()

  useEffect(() => {
    const fetchUserInfo = async () => {
      const web3 = getWeb3()
      const lendingContract = new web3.eth.Contract((masterchefABI as unknown) as AbiItem, getMasterChefAddress())
      const userInfo = await lendingContract.methods.userInfo(account).call()
       // const stakedBusd = userInfo?.busdDepositAmount ?? null;
       setData(userInfo)
      // setData({
      //   userId: userInfo.userId ? new BigNumber(userInfo.userId) : new BigNumber(0),
      //   wallet: userInfo.wallet ? userInfo.wallet : '',
      //   busdDepositAmount: userInfo.busdDepositAmount ? new BigNumber(userInfo.busdDepositAmount) : new BigNumber(0),
      //   busdBalanceAmount: userInfo.busdBalanceAmount ? new BigNumber(userInfo.busdBalanceAmount) : new BigNumber(0),
      //   totalRewardsPrepare: userInfo.totalRewardsPrepare ? new BigNumber(userInfo.totalRewardsPrepare) : new BigNumber(0),
      //   lastBlockClaim: userInfo.lastBlockClaim ? new BigNumber(userInfo.lastBlockClaim) : new BigNumber(0),
      //   jutcPerBlock: userInfo.jutcPerBlock ? new BigNumber(userInfo.jutcPerBlock) : new BigNumber(0),
      //   startBlock: userInfo.startBlock ? new BigNumber(userInfo.startBlock) : new BigNumber(0),
      //   endBlock: userInfo.endBlock ? new BigNumber(userInfo.endBlock) : new BigNumber(0),
      //   exists: userInfo.exists ? userInfo.exists : false,
      // })
    }
    if (account) {
      fetchUserInfo()
    }

   }, [account, data])
 
   return data
}

// return {
//   allowance: farm.userData ? new BigNumber(farm.userData.allowance) : new BigNumber(0),
//   tokenBalance: farm.userData ? new BigNumber(farm.userData.tokenBalance) : new BigNumber(0),
//   stakedBalance: farm.userData ? new BigNumber(farm.userData.stakedBalance) : new BigNumber(0),
//   earnings: farm.userData ? new BigNumber(farm.userData.earnings) : new BigNumber(0),
// }


export const usePendingReward = () => {
    const [data, setData] = useState(0)
    const { account }: { account: string } = useWallet()

  useEffect(() => {
    const fetchPendingReward = async () => {
        const web3 = getWeb3()
        const lendingContract = new web3.eth.Contract((masterchefABI as unknown) as AbiItem, getMasterChefAddress())
        const result = await lendingContract.methods.pendingReward(account).call()
        setData(result)
    }

    if (account) {
        fetchPendingReward()
    }
    const refreshInterval = setInterval(fetchPendingReward, 100000)
    return () => clearInterval(refreshInterval)
  }, [account, data])

  return data
}

export const useTotalBUSDdeposited = () => {
  const [data, setData] = useState(0)

  useEffect(() => {
    const fetchTotalDeposit = async () => {
      const web3 = getWeb3()
      const lendingContract = new web3.eth.Contract((masterchefABI as unknown) as AbiItem, getMasterChefAddress())
      const result = await lendingContract.methods.TVL().call()
      setData(result)
    }

    fetchTotalDeposit()
    const refreshInterval = setInterval(fetchTotalDeposit, 1000000)
    return () => clearInterval(refreshInterval)
  }, [data])

return data
}

export const useGetJUTCprice = () => {
  const [data, setData] = useState(0)
  const { account }: { account: string } = useWallet()

useEffect(() => {
  const fetchJUTCprice = async () => {
      const web3 = getWeb3()
      const lendingContract = new web3.eth.Contract((masterchefABI as unknown) as AbiItem, getMasterChefAddress())
      const result = await lendingContract.methods.getJUTCprice().call()
      setData(result)
  }

  fetchJUTCprice()
}, [account, data])

  return data
}


export const useGetTime = (blockNumberStart: string) => {
  const { account }: { account: string } = useWallet()
  const [data, setData] = useState<StakedTime>()

useEffect(() => {
  const fetchTimeDate = async () => {
      const web3 = getWeb3()
      const getDateStart = await web3.eth.getBlock(blockNumberStart)
      const dateStart = new Date(Number(getDateStart?.timestamp) * 1000)
      const dateEnd = new Date(Number(getDateStart?.timestamp) * 1000 + 47347200000)
      const dateStringStart = formatDate(dateStart.toDateString())
      const dateStringEnd = formatDate(dateEnd.toDateString())
      setData({dateStringStart, dateStringEnd})
  }
  if (account) {
    fetchTimeDate()
  }
}, [account, blockNumberStart, data])

  return data
}


const formatDate = (date: string) =>{
  const d = new Date(date);
  let month = `${d.getMonth()+1}`;
  let day = `${d.getDate()}`;
  const year = d.getFullYear();

  if (month.length < 2) 
      month = `0${month}`;
  if (day.length < 2) 
      day = `0${day}`;

  return [year, month, day].join('-');
}
import { MenuEntry } from 'socialbureau-uikitset'

const config: MenuEntry[] = [
  {
    label: 'Home',
    icon: 'HomeIcon',
    href: 'https://socialbureau.finance/',
  },
  {
    label: 'Jandra',
    icon: 'InfoIcon',
    href: 'https://socialbureau.io/jandra-token/',
  },
  {
    label: 'NFT',
    icon: 'InfoIcon',
    href: 'https://socialbureau.io/nft/',
  },
  {
    label: 'Trade',
    icon: 'TradeIcon',
    items: [
      {
        label: 'Exchange',
        href: 'https://exchange.socialbureau.finance/#/swap',
      },
      {
        label: 'Liquidity',
        href: 'https://exchange.socialbureau.finance/#/pool',
      },
      {
        label: 'Migration',
        href: 'https://socialbureau.finance/farms',
      },
    ],
  },
  {
    label: 'Farms',
    icon: 'FarmIcon',
    href: 'https://socialbureau.finance/farms',
  },
  {
    label: 'Appreciation Farms',
    icon: 'FarmIcon',
    items: [
      {
        label: 'Private Sales',
        href: 'https://private.socialbureau.finance',
      },
      {
        label: 'Early Investors',
        href: 'https://early.socialbureau.finance',
      },
    ]
  },
  {
    label: 'BUSD Lending',
    icon: 'FarmIcon',
    href: '/',
  },
]

export default config

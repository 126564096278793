/* eslint-disable react/style-prop-object */
import React, { useContext } from 'react'
import { useWallet } from '@binance-chain/bsc-use-wallet'
import { allLanguages } from 'config/localisation/languageCodes'
import { LanguageContext } from 'contexts/Localisation/languageContext'
import useTheme from 'hooks/useTheme'
import { usePriceCakeBusd } from 'state/hooks'
import { Menu as UikitMenu } from 'socialbureau-uikitset'
import { useGetJUTCprice } from 'hooks/useLending'
import BigNumber from 'bignumber.js'
import { getBalanceNumber } from 'utils/formatBalance'
import config from './config'


const jandraIcon = (
<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 463.2 462.4" enableBackground="new 0 0 463.2 462.4" xmlSpace="preserve">
<g>
	<circle fill="#FFFFFF" cx="231.4" cy="231.4" r="231.4"/>
	
		<linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="0" y1="2612.9961" x2="463.1691" y2="2612.9961" gradientTransform="matrix(1 0 0 1 0 -2381.7742)">
		<stop  offset="0" style={{ stopColor: '#120688' }} />
		<stop  offset="1" style={{ stopColor: '#01ADEE' }} />
	</linearGradient>
	<path fill="url(#SVGID_1_)" d="M188,166.7c3.1-0.8,6.2,1.1,7,4.1c0.8,3.1-1.1,6.2-4.1,7c-3.1,0.8-6.2-1.1-7-4.1
		C183,170.7,184.9,167.5,188,166.7z M351.5,178.3c0.8,3.1,4,4.9,7,4.1c3.1-0.8,4.9-4,4.1-7c-0.8-3.1-4-4.9-7-4.1
		C352.6,172,350.7,175.1,351.5,178.3z M195.7,116.3c0.8,3.1,4,4.9,7,4.1c3.1-0.8,4.9-4,4.1-7c-0.8-3.1-4-4.9-7-4.1
		C196.8,110,195,113.1,195.7,116.3z M460.7,259c-11.2,91.8-77,171.7-171.9,196.1C165.1,486.9,39.1,412.4,7.3,288.8
		S50,39.1,173.7,7.3c98.6-25.3,198.7,17,251.3,97.8l-59.6,15.3l-2.5,0.6l-64.2-38l-82,21.1c-4.3-6.2-12.3-9.5-20-7.5
		c-10,2.5-16,12.8-13.4,22.8c2.5,10,12.8,16,22.8,13.4c7.7-2,13.1-8.6,14-16.2l76.7-19.7l48.5,28.6l-140.4,36
		c-4.3-6.2-12.3-9.5-20-7.5c-10,2.5-16,12.8-13.4,22.8c2.5,10,12.8,16,22.8,13.4c7.7-2,13.1-8.6,14-16.2l152.9-39.3l7.7-2l63.4-16.3
		c5.5,9.6,10.4,19.8,14.5,30.4l-74,19c-4.3-6.2-12.3-9.5-20-7.5c-10,2.5-16,12.8-13.4,22.8c2.5,10,12.8,16,22.8,13.4
		c7.7-2,13.1-8.6,14-16.2l75.2-19.3c1.5,4.7,3,9.5,4.2,14.3c1.3,4.8,2.3,9.6,3.2,14.4l-95.7,24.6l-51.9-30.7l-57.6,14.8
		c-4.3-6.2-12.3-9.5-20-7.5c-10,2.5-16,12.8-13.4,22.8c2.5,10,12.8,16,22.8,13.4c7.7-2,13.1-8.6,14-16.2l52.4-13.4l51.9,30.7
		l99.8-25.7c2,14.9,2.5,29.6,1.6,44.2l-76.9,19.7l-12.7,3.2l-64.2-38L212,254.5c-4.3-6.2-12.3-9.5-20-7.5c-10,2.5-16,12.8-13.4,22.8
		c2.5,10,12.8,16,22.8,13.4c7.7-2,13.1-8.6,14-16.2l91.8-23.6l48.5,28.7L298.8,287c-4.3-6.2-12.3-9.5-20-7.5
		c-10,2.5-16,12.8-13.4,22.8c2.5,10,12.8,16,22.8,13.4c7.7-2,13.1-8.6,14-16.2l69-17.7l17.9-4.6l26.5-6.8L394,306.7l-116.8,30
		c-4.3-6.2-12.3-9.5-20-7.5c-10,2.5-16,12.8-13.4,22.8c2.5,10,12.8,16,22.8,13.4c7.7-2,13.1-8.6,14-16.2l122-31.3l30.7-51.9
		L460.7,259z M320.7,397c-85,1.4-163.1-55.2-185.2-141.2s19-173.3,94.2-212.9c-14.4,0.3-29.1,2.2-43.7,5.9
		C85.5,74.6,24.9,177,50.8,277.6s128.3,161.1,228.9,135.3C294.2,409.2,307.9,403.8,320.7,397z M277.2,299.3c0.8,3.1,4,4.9,7,4.1
		c3.1-0.8,4.9-4,4.1-7c-0.8-3.1-4-4.9-7-4.1C278.3,293,276.5,296.2,277.2,299.3z M255.5,349.1c0.8,3.1,4,4.9,7,4.1
		c3.1-0.8,4.9-4,4.1-7c-0.8-3.1-4-4.9-7-4.1S254.7,346,255.5,349.1z M232.1,208.9c0.8,3.1,4,4.9,7,4.1c3.1-0.8,4.9-4,4.1-7
		c-0.8-3.1-4-4.9-7-4.1C233.1,202.7,231.3,205.8,232.1,208.9z M190.2,267.1c0.8,3.1,4,4.9,7,4.1c3.1-0.8,4.9-4,4.1-7
		c-0.8-3.1-4-4.9-7-4.1S189.5,264.1,190.2,267.1z"/>
</g>
</svg>
)

const Menu = (props) => {
  const { account, connect, reset } = useWallet()
  const { selectedLanguage, setSelectedLanguage } = useContext(LanguageContext)
  const { isDark, toggleTheme } = useTheme()
  const cakePriceUsd = useGetJUTCprice()
  const cakePrice = new BigNumber(cakePriceUsd)
  const rawCakePrice = getBalanceNumber(cakePrice)

  return (
    <UikitMenu
      account={account}
      login={connect}
      logout={reset}
      isDark={isDark}
      toggleTheme={toggleTheme}
      currentLang={selectedLanguage && selectedLanguage.code}
      langs={allLanguages}
      setLang={setSelectedLanguage}
      cakePriceUsd={rawCakePrice}
      cakePriceIcon={jandraIcon}
      links={config}
      priceLink=""
	  cakePriceRef="https://swap.arken.finance/tokens/bsc/0x5e7d3c2045c914316f26c1f5ad35c16aa4c92acf"
      {...props}
    />
  )
}

export default Menu

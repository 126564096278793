export default {
  cake: {
    56: '0x5e7d3c2045c914316f26c1f5ad35c16aa4c92acf',
    97: '0xC45A9F68003Cd8E5CB125B7d1C4522B85B00EC0B', // JUTC 8/11/2021
  },
  masterChef: {
    56: '0x9C72176a0baEF4A7b258995DEC838Bae0647a3f1', // SocialLending 8/4/2022
    97: '0x5d572E09De44F18f57DfbCF9A09AE3dD2764Afa0', // Masterchef 8/11/2021
  },
  adapter: {
    56: '0xcDc20810939e86734D448b6dB6673e692E942f38', // Masterchef 30/11/2021
    97: '0xd4961DA78548956B2cEd9e07760c1F61D3867eFE', // Masterchef 8/11/2021
  },
  wbnb: {
    56: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
    97: '0xae13d989dac2f0debff460ac112a837c89baa7cd',
  },
  lottery: {
    56: '',
    97: '',
  },
  lotteryNFT: {
    56: '',
    97: '',
  },
  mulltiCall: {
    56: '0x1ee38d535d541c55c9dae27b12edf090c608e6fb',
    97: '0x67ADCB4dF3931b0C5Da724058ADC2174a9844412',
  },
  busd: {
    56: '0xe9e7cea3dedca5984780bafc599bd69add087d56',
    97: '0x322e4612792dcdd60a50f9802c823e7b51ef1e31', // Mocked BUSD
  },
  migration: {
    56: '0xa380cF9109E96A4f920b61c988E10f2980d1DE76', // ROYX Migration
    97: '0xa2A93f63aEdA847f3Ea501DecB82F0120EFAE230',
  },
  royx: {
    56: '0x47199d51a9f0dd669585f4ac2c327dac699289fd', // ROYX 
    97: '0xe70a11ce106de24664d52e9eb8e98db097e7d21c',
  },
}
